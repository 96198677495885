<template>
  <div class="attention baseConsoleTabPage">
    <div class="tabBlock">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('concernedAbout')" name="first"></el-tab-pane>
        <el-tab-pane :label="$t('followMyBusiness')" name="second"></el-tab-pane>
        <el-tab-pane :label="$t('myLikeCompany')" name="third"></el-tab-pane>
        <el-tab-pane :label="$t('likeMyCompany')" name="fourth"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="tableBlock" v-if="activeName === 'first'">
      <el-table :data="oneTableData" style="width: 100%" height="100" v-tableHeight="{bottomOffset: 74}"
                :tableLoading="oneLoading">
        <el-table-column :show-overflow-tooltip="true" :label="$t('followPerson')">
          <template slot-scope="scope">
            <!--  弹出框 -->
            <el-popover
                placement="top-start"
                width="400"
                trigger="click"
                popper-class="talkone"
            >
              <div class="company">
                <div
                    class="whiteColor"
                    :title="
                      scope.row.company_name_en
                        | priorFormat(scope.row.company_name_zh, LOCALE)
                    "
                >
                  {{
                    scope.row.company_name_en
                        | priorFormat(scope.row.company_name_zh, LOCALE)
                  }}
                </div>
              </div>
              <div class="info clearfix">
                <div class="infoLeft fl">
                  <div class="whiteColor user_name">
                      <span
                          :title="
                          scope.row.follow_user_name_en
                            | priorFormat(scope.row.follow_user_name_zh, LOCALE)
                        "
                      >{{
                          scope.row.follow_user_name_en
                              | priorFormat(scope.row.follow_user_name_zh, LOCALE)
                        }}</span
                      >
                  </div>
                  <div class="whiteColor marginT2">
                    <div class="clearfix">
                      <el-image class="fl" :src="phoneImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.mobile | textFormat }}
                      </div>
                    </div>
                    <div class="clearfix marginT1">
                      <el-image class="fl" :src="emailImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.email | textFormat }}
                      </div>
                    </div>
                    <div class="clearfix marginT1" v-if="LOCALE == 'zh'">
                      <el-image class="fl" :src="wechatImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.wechat | textFormat }}
                      </div>
                    </div>
                    <div class="clearfix marginT1" v-if="LOCALE == 'en'">
                      <el-image class="fl" :src="faceImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.facebook | textFormat }}
                      </div>
                    </div>
                  </div>
                  <div class="whiteColor country">
                    {{
                      scope.row.country
                          | countryCityFormat(
                          scope.row.region_en,
                          scope.row.region_zh,
                          LOCALE
                          )
                    }}
                  </div>
                </div>
                <div class="infoRight fr tc">
                  <el-image
                      :src="scope.row.avatar ? scope.row.avatar : default_img"
                      class="avatar"
                  ></el-image>
                  <div
                      class="marginT1"
                      :title="
                        scope.row.follow_user_job_title_en
                          | priorFormat(
                            scope.row.follow_user_job_title_zh,
                            LOCALE
                          )
                      "
                  >
                    {{
                      scope.row.follow_user_job_title_en
                          | priorFormat(
                          scope.row.follow_user_job_title_zh,
                          LOCALE
                          )
                    }}
                  </div>
                </div>
              </div>
              <span slot="reference" class="cursor">
                  {{
                  scope.row.follow_user_name_en
                      | priorFormat(scope.row.follow_user_name_zh, LOCALE)
                }}
                </span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            :show-overflow-tooltip="true"
            :label="$t('position')"
        >
          <template slot-scope="scope">
              <span>
                {{
                  scope.row.follow_user_job_title_en
                      | priorFormat(scope.row.follow_user_job_title_zh, LOCALE)
                }}
              </span>
          </template>
        </el-table-column>
        <el-table-column
            :show-overflow-tooltip="true"
            :label="$t('companyName')"
            min-width="200"
        >
          <template slot-scope="scope">
            <router-link
                class="cursor"
                :to="{
                  path: '/company/companyDetail/about',
                  query: { company_id: scope.row.followed_company_id }
                }"
                target="_blank"
            >
              {{
                scope.row.followed_company_name_en
                    | priorFormat(scope.row.followed_company_name_zh, LOCALE)
              }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
            :show-overflow-tooltip="true"
            :label="$t('payAttentionTime')"
        >
          <template slot-scope="scope">
            {{ scope.row.follow_time | secondFormat }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="tableBlock twoWrap" v-if="activeName === 'second'">
      <el-table :data="twoTableData" style="width: 100%" height="100" v-tableHeight="{bottomOffset: 74}" :tableLoading="twoLoading">
        <el-table-column :show-overflow-tooltip="true" :label="$t('followPerson')">
          <template slot-scope="scope">
            <!--  弹出框 -->
            <el-popover
                placement="top-start"
                width="400"
                trigger="click"
                popper-class="talkone"
            >
              <div class="company">
                <div
                    class="whiteColor"
                    :title="
                      scope.row.company_name_en
                        | priorFormat(scope.row.company_name_zh, LOCALE)
                    "
                >
                  {{
                    scope.row.company_name_en
                        | priorFormat(scope.row.company_name_zh, LOCALE)
                  }}
                </div>
              </div>
              <div class="info clearfix">
                <div class="infoLeft fl">
                  <div class="whiteColor user_name">
                      <span
                          :title="
                          scope.row.follow_user_name_en
                            | priorFormat(scope.row.follow_user_name_zh, LOCALE)
                        "
                      >{{
                          scope.row.follow_user_name_en
                              | priorFormat(scope.row.follow_user_name_zh, LOCALE)
                        }}</span
                      >
                  </div>
                  <div class="whiteColor marginT2">
                    <div class="clearfix">
                      <el-image class="fl" :src="phoneImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.mobile | textFormat }}
                      </div>
                    </div>
                    <div class="clearfix marginT1">
                      <el-image class="fl" :src="emailImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.email | textFormat }}
                      </div>
                    </div>
                    <div class="clearfix marginT1" v-if="LOCALE == 'zh'">
                      <el-image class="fl" :src="wechatImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.wechat | textFormat }}
                      </div>
                    </div>
                    <div class="clearfix marginT1" v-if="LOCALE == 'en'">
                      <el-image class="fl" :src="faceImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.facebook | textFormat }}
                      </div>
                    </div>
                  </div>
                  <div class="whiteColor country">
                    {{
                      scope.row.country
                          | countryCityFormat(
                          scope.row.region_en,
                          scope.row.region_zh,
                          LOCALE
                          )
                    }}
                  </div>
                </div>
                <div class="infoRight fr tc">
                  <el-image
                      :src="scope.row.avatar ? scope.row.avatar : default_img"
                      class="avatar"
                  ></el-image>
                  <div
                      class="marginT1"
                      :title="
                        scope.row.follow_user_job_title_en
                          | priorFormat(
                            scope.row.follow_user_job_title_zh,
                            LOCALE
                          )
                      "
                  >
                    {{
                      scope.row.follow_user_job_title_en
                          | priorFormat(
                          scope.row.follow_user_job_title_zh,
                          LOCALE
                          )
                    }}
                  </div>
                </div>
              </div>
              <span slot="reference" class="cursor">
                  {{
                  scope.row.follow_user_name_en
                      | priorFormat(scope.row.follow_user_name_zh, LOCALE)
                }}
                </span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            :show-overflow-tooltip="true"
            :label="$t('position')"
        >
          <template slot-scope="scope">
            {{
              scope.row.follow_user_job_title_en
                  | priorFormat(scope.row.follow_user_job_title_zh, LOCALE)
            }}
          </template>
        </el-table-column>
        <el-table-column
            :show-overflow-tooltip="true"
            :label="$t('companyName')"
            min-width="200"
        >
          <template slot-scope="scope">
            <router-link
                class="cursor"
                :to="{
                  path: '/company/companyDetail/about',
                  query: { company_id: scope.row.company_id }
                }"
                target="_blank"
            >
              {{
                scope.row.company_name_en
                    | priorFormat(scope.row.company_name_zh, LOCALE)
              }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
            :show-overflow-tooltip="true"
            :label="$t('payAttentionTime')"
        >
          <template slot-scope="scope">
            {{ scope.row.follow_time | secondFormat }}
          </template>
        </el-table-column>
      </el-table>
      <div class="mark" v-if="!memberCompany">
          {{ $t("permissionViewContact") }}
      </div>
    </div>
    <div class="tableBlock" v-if="activeName === 'third'">
      <el-table :data="threeTableData" style="width: 100%" height="100" v-tableHeight="{bottomOffset: 74}" :tableLoading="threeLoading">
        <el-table-column :show-overflow-tooltip="true" :label="$t('companyName')" min-width="200">
          <template slot-scope="scope">
            <router-link
                class="cursor"
                :to="{
                  path: '/company/companyDetail/about',
                  query: { company_id: scope.row.liked_company_id }
                }"
                target="_blank"
            >
              {{
                scope.row.company_name_en
                    | priorFormat(scope.row.company_name_zh, LOCALE)
              }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" :label="$t('bussinessCountryCity')">
          <template slot-scope="scope">
            {{
              scope.row.country
                  | countryCityFormat(
                  scope.row.region_en,
                  scope.row.region_zh,
                  LOCALE
                  )
            }}
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" :label="$t('likeCompanyTime')">
          <template slot-scope="scope">
            {{ scope.row.like_time | secondFormat }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="tableBlock fourWrap" v-if="activeName === 'fourth'">
      <el-table :data="fourTableData" style="width: 100%" height="100" v-tableHeight="{bottomOffset: 74}"
                :tableLoading="fourLoading">
        <el-table-column :show-overflow-tooltip="true" :label="$t('name')">
          <template slot-scope="scope">
            <!--  弹出框 -->
            <el-popover
                placement="top-start"
                width="400"
                trigger="click"
                popper-class="talkone"
            >
              <div class="company">
                <div
                    class="whiteColor"
                    :title="
                      scope.row.company_name_en
                        | priorFormat(scope.row.company_name_zh, LOCALE)
                    "
                >
                  {{
                    scope.row.company_name_en
                        | priorFormat(scope.row.company_name_zh, LOCALE)
                  }}
                </div>
              </div>
              <div class="info clearfix">
                <div class="infoLeft fl">
                  <div class="whiteColor user_name">
                      <span
                          :title="
                          scope.row.user_name_en
                            | priorFormat(scope.row.user_name_zh, LOCALE)
                        "
                      >{{
                          scope.row.user_name_en
                              | priorFormat(scope.row.user_name_zh, LOCALE)
                        }}</span
                      >
                  </div>
                  <div class="whiteColor marginT2">
                    <div class="clearfix">
                      <el-image class="fl" :src="phoneImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.mobile | textFormat }}
                      </div>
                    </div>
                    <div class="clearfix marginT1">
                      <el-image class="fl" :src="emailImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.email | textFormat }}
                      </div>
                    </div>
                    <div class="clearfix marginT1" v-if="LOCALE == 'zh'">
                      <el-image class="fl" :src="wechatImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.wechat | textFormat }}
                      </div>
                    </div>
                    <div class="clearfix marginT1" v-if="LOCALE == 'en'">
                      <el-image class="fl" :src="faceImg"></el-image>
                      <div class="marginL fl contact_info">
                        {{ scope.row.facebook | textFormat }}
                      </div>
                    </div>
                  </div>
                  <div class="whiteColor country">
                    {{
                      scope.row.country
                          | countryCityFormat(
                          scope.row.region_en,
                          scope.row.region_zh,
                          LOCALE
                          )
                    }}
                  </div>
                </div>
                <div class="infoRight fr tc">
                  <el-image
                      :src="scope.row.avatar ? scope.row.avatar : default_img"
                      class="avatar"
                  ></el-image>
                  <div
                      class="marginT1"
                      :title="
                        scope.row.user_job_title_en
                          | priorFormat(scope.row.user_job_title_zh, LOCALE)
                      "
                  >
                    {{
                      scope.row.user_job_title_en
                          | priorFormat(scope.row.user_job_title_zh, LOCALE)
                    }}
                  </div>
                </div>
              </div>
              <span slot="reference" class="cursor">
                  {{
                  scope.row.user_name_en
                      | priorFormat(scope.row.user_name_zh, LOCALE)
                }}
                </span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" :label="$t('position')">
          <template slot-scope="scope">
              <span>
                {{
                  scope.row.user_job_title_en
                      | priorFormat(scope.row.user_job_title_zh, LOCALE)
                }}
              </span>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" :label="$t('companyName')" min-width="200">
          <template slot-scope="scope">
            <router-link
                class="cursor"
                :to="{
                  path: '/company/companyDetail/about',
                  query: { company_id: scope.row.company_id }
                }"
                target="_blank"
            >
              {{
                scope.row.company_name_en
                    | priorFormat(scope.row.company_name_zh, LOCALE)
              }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" :label="$t('bussinessCountryCity')">
          <template slot-scope="scope">
            {{
              scope.row.country
                  | countryCityFormat(
                  scope.row.region_en,
                  scope.row.region_zh,
                  LOCALE
                  )
            }}
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" :label="$t('likeCompanyTime')">
          <template slot-scope="scope">
            {{ scope.row.like_time | secondFormat }}
          </template>
        </el-table-column>
      </el-table>
      <div class="mark" v-if="!memberCompany">
          {{ $t("permissionViewContact") }}
        </div>
    </div>
    <div class="paginationBlock">
      <el-pagination
          :page-size="10"
          layout="total, prev, pager, next"
          :total="total"
          :current-page.sync="currentPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phoneImg:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/phone.png",
      emailImg:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/email.png",
      faceImg:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/facebook.png",
      wechatImg:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/wechat.png",
      default_img:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/avatar.png",
      activeName: "first",
      oneTableData: [],
      oneLoading: false,
      twoTableData: [],
      twoLoading: false,
      threeTableData: [],
      threeLoading: false,
      fourTableData: [],
      fourLoading: false,
      total: 0,
      currentPage: 1
    };
  },
  watch: {
    currentPage() {
      if (this.activeName === "first") {
        this.getOneList();
      } else if (this.activeName === "second") {
        if (this.memberCompany) {
          this.getTwoList();
        }
      } else if (this.activeName === "third") {
        this.getThreeList();
      } else if (this.activeName === "fourth") {
        if (this.memberCompany) {
          this.getFourList();
        }
      }
    }
  },
  computed: {
    //当前是不会会员公司
    memberCompany() {
      if (this.USER_STATUS == 6 || this.USER_STATUS == 7) {
        return true;
      } else {
        return false;
      }

    }
  },
  created() {

  },
  mounted() {
   
    this.getOneList()
  },
  methods: {
    handleClick({name}) {
      this.total = 0
      this.currentPage = 1
      if (name == "first") {
        this.getOneList();
      } else if (name == "second") {
        if (this.memberCompany) {
          this.getTwoList();
        }
      } else if (name == "third") {
        this.getThreeList();
      } else if (name == "fourth") {
        if (this.memberCompany) {
          this.getFourList();
        }
      }
    },
    //我关注的
    async getOneList() {
      this.oneLoading = true
      let params = {}
      params.start = this.currentPage - 1
      params.limit = 10
      params.nopage = 0
      params.company_id = this.$store.state.baseStore.userInfo.company_id
      try {
        let res = await this.$store.dispatch('baseConsole/getCompanyFollowUpList', params)
        if (res.success) {
        
          this.oneTableData = res.data
          this.total = res.total
          this.oneLoading = false
        }
      } catch (e) {
        this.oneLoading = false;
      }
    },
    //关注我的
    async getTwoList() {
      this.twoLoading = true
      let params = {}
      params.start = this.currentPage - 1
      params.limit = 10
      params.nopage = 0
      params.company_id = this.$store.state.baseStore.userInfo.company_id
      try {
        let res = await this.$store.dispatch('baseConsole/getCompanyFollowedList',params)
        
        if (res.success) {
          this.twoTableData = res.data
         
          this.total = res.total
          this.twoLoading = false
        }
      } catch (e) {
        console.log(e)
        this.twoLoading = false
      }
    },
    //我点赞的
    async getThreeList() {
      this.threeLoading = false
      let params = {}
      params.start = this.currentPage - 1
      params.limit = 10
      params.nopage = 0
      params.company_id = this.$store.state.baseStore.userInfo.company_id
      try {
        let res = await this.$store.dispatch('baseConsole/getMyLikeList', params)
        if (res.success) {
         
          this.threeTableData = res.data
          this.total = res.count
          this.threeLoading = false
        }
      } catch (e) {
        this.threeLoading = false
      }
    },
    //点赞我的
    async getFourList() {
      this.fourLoading = true
      let params = {}
      params.start = this.currentPage - 1
      params.limit = 10
      params.nopage = 0
      params.company_id = this.$store.state.baseStore.userInfo.company_id
      try {
        let res = await this.$store.dispatch('baseConsole/getVistCompanyLikeList', params)
        if (res.success) {
          
          this.fourTableData = res.data
          this.total = res.total
          this.fourLoading = false
        }
      } catch (e) {
        this.fourLoading = false
      }
    }
  },
};
</script>

<style scoped lang="less">
.footer {
  margin-top: 10px;
  text-align: right;
}

.cursor {
  color: rgb(0, 131, 246);
  cursor: pointer;
}

.company {
  height: 25px;

  div {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  div:nth-child(2) {
    color: #666666;
  }
}

.avatar {
  width: 73px;
  height: 73px;
  border-radius: 50%;
}

.marginTB {
  margin-bottom: 10px;
}

.info {
  margin-top: 15px;

  div,
  span {
    color: #fff;
  }

  .infoLeft {
    width: 70%;

    .user_name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }

  .infoRight {
    width: 30%;
    margin: 0 auto;

    div:nth-child(2) {
      max-height: 100px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }

  .country {
    margin-top: 18px;
  }
}

.marginL {
  margin-left: 10px;
}

.marginT {
  margin-top: 30px;
}

.marginT1 {
  margin-top: 3px;
}

.marginT2 {
  margin-top: 10px;
}

.posirion-wrap {
  position: relative;
}

.mark {
  position: absolute;
  width: 100%;
  height: 600px;
  top: 49px;
  left: 0;
  text-align: center;
  line-height: 500px;
  background: #fff;
  font-size: 14px;
}

/deep/ .active.el-table::before {
  display: none;
}
.twoWrap,.fourWrap{
  position:relative;
}
  .mark {
    position: absolute;
    width: 100%;
    height: 600px;
    top: 49px;
    left: 0;
    text-align: center;
    line-height: 600px;
    background: #fff;
    font-size: 14px;
  }

</style>
